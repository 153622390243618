import React from "react"
import Layout from "../components/layout"
import Styles from "./css/404.module.css"
import { Helmet } from "react-helmet"

const ContactUs = () => (
    <Layout>
        <Helmet>
            <title>Contact Us - Colorful Phone Cases</title>
            <meta name="description" content="Have a question contact us and we will get back to you as soon as possible." />
        </Helmet>

        <h1 className={Styles.title}>Contact Us</h1>

        <form className={Styles.contactFormMain} name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <p className={Styles.text} className={Styles.contactForm}>
            <label>Your Email Address: </label>
            <input type="email" name="email" required/>
        </p>
        <p className={Styles.text} className={Styles.contactForm}>
            <label>Subject: </label>
            <input type="text" name="subject" required/>
        </p>
        <p className={Styles.text} className={Styles.contactForm}>
            <label>Message </label>
                <textarea className={Styles.textBox} name="message" required></textarea>
        </p>
        <p className={Styles.text}>
            <button className={Styles.sendButton} type="submit">Send</button>
        </p>
        </form>

        <p className={Styles.text}>Or you can send us an email directly: colorfulphonecasesinfo@gmail.com</p>

    </Layout>
)

export default ContactUs